import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
import styles from './consultaPoligonos.module.css';

const ConsultaPoligonos = () => {
    const [poligonos, setPoligonos] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [zonaToDelete, setZonaToDelete] = useState(null);

    // Cargar polígonos desde el servidor
    useEffect(() => {
        const fetchPoligonos = async () => {
            try {
                const response = await fetch('https://mapane3.tipicaweb.com/api/api/getPolygons');
                const data = await response.json();
                setPoligonos(data);
            } catch (error) {
                console.error('Error fetching polígonos:', error);
            }
        };
        fetchPoligonos();
    }, []);

    // Filtrar polígonos según el término de búsqueda
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredPoligonos = poligonos.filter((poligono) =>
        poligono.nombre_zona?.toLowerCase().includes(searchTerm.toLowerCase() || '')
    );

    // Abrir el diálogo de confirmación
    const openDeleteConfirmation = (nombre_zona) => {
        setZonaToDelete(nombre_zona);
        setOpenDeleteDialog(true);
    };

    // Cerrar el diálogo de confirmación
    const closeDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setZonaToDelete(null);
    };

    // Función para manejar la eliminación del polígono
    const handleDelete = async () => {
        try {
            // Realizar la solicitud DELETE al backend
            const response = await fetch(
                `https://mapane3.tipicaweb.com/api/api/deletePolygon`, // Asegúrate de que la URL sea correcta
                {
                    method: 'DELETE',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ nombre_zona: zonaToDelete }), // Pasamos el nombre del polígono
                }
            );

            if (response.ok) {
                // Actualizar el estado para eliminar el polígono de la lista
                setPoligonos(poligonos.filter((poligono) => poligono.nombre_zona !== zonaToDelete));
                closeDeleteDialog();
            } else {
                console.error('Error al eliminar el polígono:', await response.text());
            }
        } catch (error) {
            console.error('Error al eliminar el polígono:', error);
        }
    };

    return (
        <div className={styles.container}>
            <header className={styles.header}>
                <h1>Consulta de Polígonos</h1>
            </header>
            <input
                type="text"
                placeholder="Buscar por nombre de zona..."
                value={searchTerm}
                onChange={handleSearch}
                className={styles['search-input']}
            />
            <table className={styles['poligonos-table']}>
                <thead>
                    <tr>
                        <th>Polígono</th>
                        <th>Editar</th>
                        <th>Borrar</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredPoligonos.map((poligono, index) => (
                        <tr key={index}>
                            <td>{poligono.nombre_zona}</td>
                            <td>
                                <Link
                                    to={`/editar-poligono/${poligono.nombre_zona}`}
                                    className={styles['icon-button']}
                                >
                                    <EditIcon style={{ color: 'blue' }} />
                                </Link>
                            </td>
                            <td>
                                <button
                                    onClick={() => openDeleteConfirmation(poligono.nombre_zona)}
                                    className={styles['icon-button']}
                                >
                                    <DeleteIcon style={{ color: 'red' }} />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Diálogo de confirmación para eliminar */}
            <Dialog open={openDeleteDialog} onClose={closeDeleteDialog}>
                <DialogTitle>Confirmar Eliminación</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        ¿Estás seguro de que deseas eliminar el polígono "{zonaToDelete}"? Esta acción no se puede
                        deshacer.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteDialog} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleDelete} color="secondary">
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ConsultaPoligonos;
