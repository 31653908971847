import React, { useState } from 'react';
import { IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import WarningIcon from '@mui/icons-material/Warning'; // Icono de advertencia
import { useNavigate } from 'react-router-dom';
import './Header.css'; // Importa el archivo CSS
import banner from '../../assets/NE3_banner.png'; // Ruta al nuevo banner

function Header() {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const handleLogoutClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirmLogout = () => {
        localStorage.removeItem('token');
        navigate('/login');
        setOpen(false);
    };

    return (
        <header className="header">
            <div className="logo-container">
                <img src={banner} alt="NE3 Banner" className="header-logo" />
            </div>
            <div className="header-icons">
                <IconButton id="logout-button"  onClick={handleLogoutClick}>
                    <LogoutIcon />
                </IconButton>
            </div>

            <Dialog open={open} onClose={handleClose} className="logout-dialog">
                <DialogTitle>
                    <WarningIcon style={{ color: 'orange', marginRight: '8px' }} />
                    Confirmar Salida
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        ¿Seguro que deseas salir?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button id="cancel-button" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button id="confirm-button" onClick={handleConfirmLogout}>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </header>
    );
}

export default Header;
