import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Header from '../../shared/header/Header';
import BusCheckboxes from '../mymaps/BusCheckboxes';
import { Autocomplete, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { SvgIcon } from '@mui/material';

import './EditMapPage.css';

const EditMapPage = () => {
    const { id } = useParams();
    const [map, setMap] = useState(null);
    const [selectedBuses, setSelectedBuses] = useState([]);
    const [busLines, setBusLines] = useState([]);
    const [polylines, setPolylines] = useState([]);
    const [polygons, setPolygons] = useState([]);
    const [markers, setMarkers] = useState([]);
    const [showDialog, setShowDialog] = useState(false); // Estado para el diálogo
    const [newMapName, setNewMapName] = useState(''); // Estado para el nombre del mapa
    const [searchTerm, setSearchTerm] = useState(''); // Término buscado
    const [polygonSuggestions, setPolygonSuggestions] = useState([]); // Opciones sugeridas
    const [suggestions, setSuggestions] = useState([]);
    const [puntosInteresSuggestions, setPuntosInteresSuggestions] = useState([]); // Para las sugerencias de puntos de interés



    useEffect(() => {
        if (window.google) {
            initMap();
        } else {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyD1Nl9yulU0jgl6VRGkK89neIalMrblrws&callback=initMap`;
            script.async = true;
            document.body.appendChild(script);
            window.initMap = initMap;
        }
    }, []);

    useEffect(() => {
        if (map) {
            fetchMapDetails();
        }
    }, [map]);

    const initMap = () => {
        const mapInstance = new window.google.maps.Map(document.getElementById('map'), {
            center: { lat: -34.391911, lng: -58.661421 },
            zoom: 12,
        });
        setMap(mapInstance);
    };

    useEffect(() => {
        const fetchBusLines = async () => {
            try {
                const response = await axios.get('https://mapane3.tipicaweb.com/api/api/getLineas');
                setBusLines(response.data);
            } catch (error) {
                console.error('Error fetching bus lines:', error);
            }
        };
        fetchBusLines();
    }, []);
    
    useEffect(() => {
        console.log('polygonSuggestions actualizado:', polygonSuggestions);
    }, [polygonSuggestions]);

    const handleSelectBus = async (routeNumber, variants) => {
        if (selectedBuses.includes(routeNumber)) return;
    
        const linePath = variants.map(coordinate => ({
            lat: parseFloat(coordinate.lat),
            lng: parseFloat(coordinate.lng),
        }));
    
        const polyline = new window.google.maps.Polyline({
            path: linePath,
            geodesic: true,
            strokeColor: variants[0].color,
            strokeOpacity: 1.0,
            strokeWeight: 2,
        });
    
        polyline.setMap(map);
        setPolylines(prevPolylines => [...prevPolylines, polyline]);
        setSelectedBuses(prevSelected => [...prevSelected, routeNumber]);
    };
    
    

    const handleRemoveBus = (routeNumber, polyline) => {
        if (polyline) {
            polyline.setMap(null);
            setPolylines(prevPolylines => prevPolylines.filter(p => p.routeNumber !== routeNumber));
        }
        setSelectedBuses(prevSelected => prevSelected.filter(bus => bus !== routeNumber));
    };

    const handleRemovePolygon = (nombre_zona) => {
        setPolygons((prevPolygons) => {
            // Filtrar y eliminar los polígonos del mapa
            return prevPolygons.filter(({ nombre_zona: zona, polygon }) => {
                if (zona === nombre_zona) {
                    polygon.setMap(null); // Eliminar del mapa
                    return false;         // Excluir del estado
                }
                return true; // Mantener los demás
            });
        });
    };
    
    
    
    

    const handleRemoveMarker = (markerInstance) => {
        markerInstance.setMap(null); // Elimina del mapa
        setMarkers((prevMarkers) => prevMarkers.filter((m) => m !== markerInstance)); // Actualiza el estado
    };
    

    const fetchPolygonSuggestions = async (term) => {
        try {
            console.log('Buscando sugerencias para:', term); // Agregar log
            const response = await axios.get(`https://mapane3.tipicaweb.com/api/api/getPolygonPoints/${term}`);
            console.log('Sugerencias recibidas:', response.data); // Agregar log
            const results = response.data;
    
            // Procesar resultados
            const groupedSuggestions = results.reduce((acc, item) => {
                const existing = acc.find((zone) => zone.nombre_zona === item.nombre_zona);
                if (existing) {
                    existing.coordinates.push({ lat: item.lat, lng: item.lng });
                } else {
                    acc.push({
                        nombre_zona: item.nombre_zona,
                        coordinates: [{ lat: item.lat, lng: item.lng }],
                        color: item.color,
                    });
                }
                return acc;
            }, []);
            setPolygonSuggestions(groupedSuggestions);
        } catch (error) {
            console.error('Error fetching polygon suggestions:', error);
        }
    };
    
    
    
    const fetchPuntosInteres = async (term) => {
        if (!term || term.trim() === '') return; // Evita solicitudes innecesarias
        try {
            const response = await axios.get(`https://mapane3.tipicaweb.com/api/api/getPuntosInteresByNombre/${term}`);
            setPuntosInteresSuggestions(response.data);
        } catch (error) {
            console.error('Error al obtener puntos de interés:', error);
        }
    };
    

    const handleSelectPuntoInteres = (selectedPoint) => {
        const marker = new window.google.maps.Marker({
            position: { lat: parseFloat(selectedPoint.lat), lng: parseFloat(selectedPoint.lng) },
            map: map,
            title: selectedPoint.nombre,
            icon: selectedPoint.icono || undefined, // Icono personalizado, si está disponible
        });
    
        // Agregar evento para eliminar marcador con clic derecho
        marker.addListener('rightclick', () => {
            handleRemoveMarker(marker);
        });
    
        // Agregar marcador al estado
        setMarkers((prevMarkers) => [...prevMarkers, marker]);
    
        // Limpiar búsqueda y sugerencias
        setSearchTerm('');
        setPuntosInteresSuggestions([]);
    };
    

    const handleSelectSuggestion = (selectedPoint) => {
        const marker = new window.google.maps.Marker({
            position: { lat: parseFloat(selectedPoint.lat), lng: parseFloat(selectedPoint.lng) },
            map: map,
            title: selectedPoint.nombre,
            icon: selectedPoint.icono || undefined, // Icono personalizado, si está disponible
        });
    
        // Agregar evento para eliminar marcador al hacer clic derecho
        marker.addListener('rightclick', () => {
            handleRemoveMarker(marker);
        });
    
        // Agregar marcador al estado
        setMarkers((prevMarkers) => [...prevMarkers, marker]);
    
        // Limpiar la búsqueda
        setSearchTerm('');
        setSuggestions([]);
    };
    

    //dibuja los elementos en el mapa

    const fetchMapDetails = async () => {
        try {
            const response = await axios.get(`https://mapane3.tipicaweb.com/api/api/getMap/${id}`);
            const mapData = response.data;
    
            // Manejar líneas
            const lineGroups = {};
            mapData.lineas.forEach((line) => {
                if (!lineGroups[line.color]) {
                    lineGroups[line.color] = [];
                }
                lineGroups[line.color].push({ lat: parseFloat(line.lat), lng: parseFloat(line.lng) });
            });
    
            Object.keys(lineGroups).forEach(color => {
                const linePath = lineGroups[color];
                const polyline = new window.google.maps.Polyline({
                    path: linePath,
                    geodesic: true,
                    strokeColor: color,
                    strokeOpacity: 1.0,
                    strokeWeight: 2,
                });
                polyline.setMap(map);
                polyline.addListener('click', () => handleRemoveBus(color, polyline));
                setPolylines(prevPolylines => [...prevPolylines, polyline]);
            });
    
            // Manejar polígonos
            const polygonGroups = {};
            mapData.poligonos.forEach((polygon) => {
                if (!polygonGroups[polygon.color]) {
                    polygonGroups[polygon.color] = [];
                }
                polygonGroups[polygon.color].push({ lat: parseFloat(polygon.lat), lng: parseFloat(polygon.lng) });
            });
    
            Object.keys(polygonGroups).forEach(color => {
                const polygonPath = polygonGroups[color];
                const polygonInstance = new window.google.maps.Polygon({
                    paths: polygonPath,
                    strokeColor: color,
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: color,
                    fillOpacity: 0.35, // Asegura que el relleno sea visible
                    clickable: true,  // Permite clics en toda el área del polígono
                });
                polygonInstance.setMap(map);
    
                // Crear un marcador de "X" para cerrar usando el componente DeleteIcon
                const bounds = new window.google.maps.LatLngBounds();
                polygonPath.forEach(coord => bounds.extend(coord));
    
                const center = bounds.getCenter();
    
                // Convertir DeleteIcon a un formato que Google Maps pueda usar como icono
                const iconUrl = "data:image/svg+xml;base64," + btoa(
                    `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g><path d="M19 3H5c-1.1 0-1.99.9-1.99 2L3 19c0 1.1.9 2 1.99 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-1 16H6V8h12v11zm-6-7h-2v2h2v-2zM12 7h-2v2h2V7z"/></g></svg>`
                );
    
                const closeMarker = new window.google.maps.Marker({
                    position: center,
                    map: map,
                    icon: {
                        url: iconUrl, // Icono generado desde DeleteIcon
                        scaledSize: new window.google.maps.Size(30, 30), // Tamaño del icono
                    },
                    title: "Eliminar polígono",
                });
    
                // Evento de clic en el marcador "X" para eliminar el polígono y el marcador
                closeMarker.addListener("click", () => {
                    polygonInstance.setMap(null); // Eliminar el polígono
                    closeMarker.setMap(null); // Eliminar el marcador de "X"
                    setPolygons(prevPolygons => prevPolygons.filter(p => p.polygon !== polygonInstance));
                });
    
                // Guardar el polígono y su marcador en el estado
                setPolygons((prevPolygons) => [
                    ...prevPolygons,
                    { nombre_zona: color, polygon: polygonInstance, closeMarker },
                ]);
            });
    
            // Manejar puntos de interés
            mapData.puntosInteres.forEach(point => {
                const marker = new window.google.maps.Marker({
                    position: { lat: point.lat, lng: point.lng },
                    map: map,
                    title: point.nombre || 'Punto de Interés',
                    icon: point.icono || null,
                });
                marker.addListener('click', () => handleRemoveMarker(marker));
                setMarkers(prevMarkers => [...prevMarkers, marker]);
            });
        } catch (error) {
            console.error('Error fetching map details:', error);
            alert('Error al cargar los detalles del mapa');
        }
    };
    
    


    const handlePolygonSelection = (selectedPolygon) => {
        if (!selectedPolygon || !selectedPolygon.coordinates) return;
    
        const polygon = new window.google.maps.Polygon({
            paths: selectedPolygon.coordinates,
            strokeColor: selectedPolygon.color,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: selectedPolygon.color,
            fillOpacity: 0.35, // Asegura que el relleno sea visible
            clickable: true,   // Permite clics en toda el área
        });
    
        polygon.setMap(map);
    
        polygon.addListener('click', () => {
            const confirmDelete = window.confirm(`¿Eliminar la zona ${selectedPolygon.nombre_zona}?`);
            if (confirmDelete) {
                handleRemovePolygon(selectedPolygon.nombre_zona);
            }
        });
    
        setPolygons((prevPolygons) => [
            ...prevPolygons,
            { nombre_zona: selectedPolygon.nombre_zona, polygon },
        ]);
    };
    
    const handleOpenDialog = () => {
        setShowDialog(true);
    };

    const handleCloseDialog = () => {
        setShowDialog(false);
        setNewMapName('');
    };

    //metodo para guardar mapa

    const handleSave = async () => {
        if (!newMapName) {
            alert('Por favor, ingresa un nombre para el mapa');
            return;
        }
    
        try {
            // Convertir polylines a un formato válido
            const lineas = polylines.map(polyline => ({
                path: polyline.getPath().getArray().map(coord => ({
                    lat: coord.lat(),
                    lng: coord.lng(),
                })),
                color: polyline.strokeColor,
            }));
    
            // Convertir polygons a un formato válido
            const poligonos = polygons.map(polygon => ({
                path: polygon.getPath().getArray().map(coord => ({
                    lat: coord.lat(),
                    lng: coord.lng(),
                })),
                color: polygon.strokeColor,
            }));
    
            // Convertir markers a un formato válido
            const puntosInteres = markers.map(marker => ({
                lat: marker.getPosition().lat(),
                lng: marker.getPosition().lng(),
                nombre: marker.getTitle(),
                icono: marker.getIcon() || null,
            }));
    
            // Enviar solo los datos que existen actualmente
            const data = {
                name: newMapName,
                data: {
                    lineas,
                    poligonos,
                    puntosInteres,
                },
            };
    
            await axios.put(`https://mapane3.tipicaweb.com/api/api/updateMap/${id}`, data);
            alert('Mapa guardado exitosamente');
    
            // Refrescar la página solo después de confirmar que los datos están sincronizados
            fetchMapDetails();
            handleCloseDialog();
        } catch (error) {
            console.error('Error al guardar el mapa:', error);
            alert('Hubo un error al guardar el mapa');
        }
    };
    
    
    
    return (
        <div className="container">
            <Header />
    
            {/* Checkboxes para gestionar elementos seleccionados */}
            <BusCheckboxes
                selectedElements={selectedBuses}
                handleSelectElement={handleSelectBus}
                handleRemoveElement={handleRemoveBus}
            />
    
            {/* Autocomplete para buscar zonas y puntos de interés */}
            <div className="autocomplete-container">
                {/* Autocomplete para zonas */}
                <Autocomplete
  options={polygonSuggestions}
  getOptionLabel={(option) => option.nombre_zona}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Buscar Poligono"
      variant="outlined"
      fullWidth
    />
  )}
  onInputChange={(event, value) => {
    setSearchTerm(value);
    // Llamar al backend con el primer carácter ingresado
    fetchPolygonSuggestions(value);
  }}
  onChange={(event, selectedOption) => {
    if (selectedOption) {
      handlePolygonSelection(selectedOption);
    }
  }}
  noOptionsText="No se encontraron zonas"
/>

    
                {/* Autocomplete para puntos de interés */}
                <Autocomplete
    options={puntosInteresSuggestions}
    getOptionLabel={(option) => option.nombre || ''} // Manejo seguro del label
    renderInput={(params) => (
        <TextField
            {...params}
            label="Buscar puntos de interés"
            variant="outlined"
            fullWidth
        />
    )}
    onInputChange={(event, value) => {
        if (event && event.type === 'change') {
            setSearchTerm(value);
            if (value.trim() !== '') {
                fetchPuntosInteres(value); // Llama al backend con el valor ingresado
            } else {
                setPuntosInteresSuggestions([]); // Limpia las sugerencias si la entrada está vacía
            }
        }
    }}
    onChange={(event, selectedOption) => {
        if (selectedOption) {
            handleSelectPuntoInteres(selectedOption); // Procesa el punto seleccionado
        }
    }}
    noOptionsText="No se encontraron puntos"
/>

            </div>
    
            {/* Botón para guardar el mapa */}
            <button className="save-button" onClick={handleOpenDialog}>
                Guardar Mapa
            </button>
    
            {/* Contenedor del mapa */}
            <div id="map" className="map"></div>
    
            {/* Modal para guardar el mapa */}
            {showDialog && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>Nuevo nombre del mapa</h2>
                        <input
                            type="text"
                            value={newMapName}
                            onChange={(e) => setNewMapName(e.target.value)}
                            placeholder="Ingresa el nuevo nombre del mapa"
                        />
                        <div className="modal-buttons">
                            <button onClick={handleSave}>Guardar</button>
                            <button onClick={handleCloseDialog}>Cancelar</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
    
}    

export default EditMapPage;
