import React, { useState, useEffect } from 'react'; 
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material'; 
import DeleteIcon from '@mui/icons-material/Delete'; 
import EditIcon from '@mui/icons-material/Edit'; // Importar el ícono de editar
import { Link } from 'react-router-dom'; 
import styles from './consultaMarcadores.module.css'; 

const ConsultaMarcadores = () => { 
    const [marcadores, setMarcadores] = useState([]); 
    const [searchTerm, setSearchTerm] = useState(''); 
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false); 
    const [nombreToDelete, setNombreToDelete] = useState(null); 

    useEffect(() => { 
        const fetchMarcadores = async () => { 
            try { 
                const response = await fetch('https://mapane3.tipicaweb.com/api/api/getPuntosInteresAgrupados'); 
                const data = await response.json(); 
                setMarcadores(data); 
            } catch (error) { 
                console.error('Error fetching marcadores:', error); 
            } 
        }; 
        fetchMarcadores(); 
    }, []); 

    const handleSearch = (event) => { 
        setSearchTerm(event.target.value); 
    }; 

    const filteredMarcadores = marcadores.filter((marcador) => 
        marcador.nombre.toLowerCase().includes(searchTerm.toLowerCase()) 
    ); 

    const openDeleteConfirmation = (nombre) => { 
        setNombreToDelete(nombre); 
        setOpenDeleteDialog(true); 
    }; 

    const closeDeleteDialog = () => { 
        setOpenDeleteDialog(false); 
        setNombreToDelete(null); 
    }; 

    const handleDelete = async () => { 
        try { 
            const response = await fetch(`https://mapane3.tipicaweb.com/api/api/deletePuntosInteresPorNombre/${nombreToDelete}`, { method: 'DELETE' }); 
            if (response.ok) { 
                setMarcadores(marcadores.filter((marcador) => marcador.nombre !== nombreToDelete)); 
                closeDeleteDialog(); 
            } else { 
                console.error('Error al eliminar el marcador:', await response.text()); 
            } 
        } catch (error) { 
            console.error('Error al eliminar el marcador:', error); 
        } 
    }; 

    return ( 
        <div className={styles.container}> 
            <header className={styles.header}> 
                <h1>Marcadores de Interés</h1> 
            </header> 
            <input 
                type="text" 
                placeholder="Buscar por nombre..." 
                value={searchTerm} 
                onChange={handleSearch} 
                className={styles['search-input']} 
            /> 
            <table className={styles['marcadores-table']}> 
                <thead> 
                    <tr> 
                        <th>Nombre</th> 
                        <th>Editar</th> {/* Nueva columna de editar */} 
                        <th>Borrar</th> 
                    </tr> 
                </thead> 
                <tbody> 
                    {filteredMarcadores.map((marcador, index) => ( 
                        <tr key={index}> 
                            <td>{marcador.nombre}</td> 
                            <td> 
                                <Link to={`/editar/${marcador.nombre}`} className={styles['icon-button']}> {/* Redirige a la página de edición */} 
                                    <EditIcon style={{ color: 'blue' }} /> 
                                </Link> 
                            </td> 
                            <td> 
                                <button 
                                    onClick={() => openDeleteConfirmation(marcador.nombre)} 
                                    className={styles['icon-button']} 
                                > 
                                    <DeleteIcon style={{ color: 'red' }} /> 
                                </button> 
                            </td> 
                        </tr> 
                    ))} 
                </tbody> 
            </table> 

            {/* Diálogo de confirmación para eliminar */} 
            <Dialog open={openDeleteDialog} onClose={closeDeleteDialog}> 
                <DialogTitle>Confirmar Eliminación</DialogTitle> 
                <DialogContent> 
                    <DialogContentText> 
                        ¿Estás seguro de que deseas eliminar los marcadores asociados al nombre "{nombreToDelete}"? Esta acción no se puede deshacer. 
                    </DialogContentText> 
                </DialogContent> 
                <DialogActions> 
                    <Button onClick={closeDeleteDialog} color="primary">Cancelar</Button> 
                    <Button onClick={handleDelete} color="secondary">Aceptar</Button> 
                </DialogActions> 
            </Dialog> 
        </div> 
    ); 
}; 

export default ConsultaMarcadores;
