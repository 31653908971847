import React, { useState } from 'react';
import { Button, TextField, Box, Alert, Snackbar, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconSelector from '../commons/IconSelector';
import axios from 'axios';
import ExcelUploader from '../../Upload-excel';
import MapWithMarkers from '../commons/MapWithMarkers';
import './marcadoresInteres.css';

const MarcadoresInteres = () => {
    const [nombre, setNombre] = useState('');
    const [excelCargado, setExcelCargado] = useState(false);
    const [iconoSeleccionado, setIconoSeleccionado] = useState('');
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('success');
    const [dialogOpen, setDialogOpen] = useState(false); // Estado para el modal
    const [data, setData] = useState([]); // Almacena los datos del Excel
    const [geocodedData, setGeocodedData] = useState([]); // Almacena los datos geocodificados


    const handleExcelUpload = async (jsonData) => {
        setData(jsonData);
        setExcelCargado(true);
        try {
            const geocodedData = await Promise.all(jsonData.map(async (item) => {
                const address = `${item.direccion}, ${item.localidad}`;
                const location = await geocodeAddress(address);
                return {
                    ...item,
                    lat: location.lat,
                    lng: location.lng
                };
            }));
            setGeocodedData(geocodedData); // Actualizar el estado con los datos geocodificados
        } catch (error) {
            setAlertMessage('Error al geocodificar los datos');
            setAlertSeverity('error');
            setAlertOpen(true);
            console.error('Error al geocodificar los datos:', error); // Log para depuración
        }
    };

    const geocodeAddress = async (address) => {
        const apiKey = 'AIzaSyDhV1-TmsTWBVC3TMbc_ATcflMHSC2Y9Wo'; // Reemplaza con tu clave de API de Google Maps
        const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
            params: {
                address: address,
                key: apiKey
            }
        });
        if (response.data.status === 'OK') {
            const location = response.data.results[0].geometry.location;
            return location;
        } else {
            throw new Error('Geocoding failed');
        }
    };

    const handleSave = async () => {
        if (!nombre || data.length === 0 || !iconoSeleccionado) {
            setAlertMessage('Nombre, ícono o datos faltantes');
            setAlertSeverity('error');
            setAlertOpen(true);
            return;
        }

        try {
            const geocodedData = await Promise.all(data.map(async (item) => {
                const address = `${item.direccion}, ${item.localidad}`;
                const location = await geocodeAddress(address);
                return {
                    ...item,
                    lat: location.lat,
                    lng: location.lng
                };
            }));
            setGeocodedData(geocodedData); // Actualizar el estado con los datos geocodificados

            // Enviar los datos al backend
            await axios.post('https://mapane3.tipicaweb.com/api/api/uploadPuntosInteres', {
                data: geocodedData, // Datos del Excel con coordenadas
                nombre, // Nombre para asociar
                icono: iconoSeleccionado // Icono seleccionado
            });

            setAlertMessage('Datos guardados exitosamente');
            setAlertSeverity('success');
            setAlertOpen(true);
            // Limpiar los campos después de guardar
            setNombre('');
            setData([]);
            setIconoSeleccionado('');
            setExcelCargado(false);
        } catch (error) {
            setAlertMessage('Error al guardar los datos');
            setAlertSeverity('error');
            setAlertOpen(true);
            console.error('Error al guardar los datos:', error); // Log para depuración
        }
    };
    const handleIconSelection = (icon) => {
        setIconoSeleccionado(icon); // Actualizar el ícono seleccionado
        setDialogOpen(false); // Cerrar el diálogo
    };

    return (
        <div className="container2">
            <Box className="file-controls2">
                <ExcelUploader setData={handleExcelUpload} />
                {excelCargado && (
                    <Box display="flex" alignItems="center" className="file-upload-message2">
                        <CheckCircleIcon color="success" />
                        <span>Archivo Excel cargado</span>
                    </Box>
                )}
                
                {/* Campo de texto para el nombre */}
                <TextField
                    placeholder="Nombre"
                    id="marcadores-custom-input"
                    value={nombre}
                    onChange={(e) => setNombre(e.target.value)}
                />
                
                {/* Botón Guardar alineado a la izquierda */}
                <Button
                    variant="contained"
                    startIcon={<SaveIcon />}
                    onClick={handleSave}
                    id="save-button2"  // Usar el ID del botón similar al de la otra ventana
                >
                    Guardar
                </Button>
    
                {/* Botón para seleccionar ícono */}
                <Button
                    id="marcadores-icon-button2"
                    variant="contained"
                    onClick={() => setDialogOpen(true)}
                    aria-haspopup="true" // Añadido para mejorar la accesibilidad
                >
                    Seleccionar Ícono
                </Button>
    
                {/* Mostrar ícono seleccionado */}
                {iconoSeleccionado && (
                    <Box display="flex" alignItems="center">
                        <img src={iconoSeleccionado} alt="Ícono seleccionado" style={{ width: 32, height: 32 }} />
                    </Box>
                )}
            </Box>
    
            {/* Aquí se pasa el iconoSeleccionado al componente MapWithMarkers */}
            <MapWithMarkers id="marcadores-map" data={geocodedData} iconoSeleccionado={iconoSeleccionado} />
    
            {/* Modal para seleccionar ícono */}
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle>Seleccionar Ícono</DialogTitle>
                <DialogContent>
                    <IconSelector onSelectIcon={handleIconSelection} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)}>Cerrar</Button>
                </DialogActions>
            </Dialog>
    
            {/* Snackbar para mostrar alertas */}
            <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={() => setAlertOpen(false)}
            >
                <Alert onClose={() => setAlertOpen(false)} severity={alertSeverity}>
                    {alertMessage}
                </Alert>
            </Snackbar>
        </div>
    );
    
};

export default MarcadoresInteres;
