import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Cambio aquí
import DeleteIcon from '@mui/icons-material/Delete';
import GetAppIcon from '@mui/icons-material/GetApp';
import EditIcon from '@mui/icons-material/Edit'; // Ícono de editar
import * as XLSX from 'xlsx';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import './searchMyMapas.css';

const SearchMyMapas = () => {
    const [maps, setMaps] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openExportDialog, setOpenExportDialog] = useState(false);
    const [mapToDelete, setMapToDelete] = useState(null);
    const [exportedMap, setExportedMap] = useState(null);
    const navigate = useNavigate(); // Cambio aquí para usar navigate

    useEffect(() => {
        const fetchMaps = async () => {
            try {
                const response = await fetch('https://mapane3.tipicaweb.com/api/api/getAllMaps');
                const data = await response.json();
                setMaps(data);
            } catch (error) {
                console.error('Error fetching maps:', error);
            }
        };
        fetchMaps();
    }, []);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredMaps = maps.filter(map =>
        map.map_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const openDeleteConfirmation = (id) => {
        setMapToDelete(id);
        setOpenDeleteDialog(true);
    };

    const closeDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setMapToDelete(null);
    };

    const handleDelete = async () => {
        try {
            await fetch(`https://mapane3.tipicaweb.com/api/api/deleteMap/${mapToDelete}`, { method: 'DELETE' });
            setMaps(maps.filter(map => map.id !== mapToDelete));
            closeDeleteDialog();
        } catch (error) {
            console.error('Error al eliminar el mapa:', error);
        }
    };

    const handleExport = (map) => {
        const worksheet = XLSX.utils.json_to_sheet([map]);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Mapa');
        XLSX.writeFile(workbook, `Mapa_${map.map_name}.xlsx`);
        setExportedMap(map);
        setOpenExportDialog(true); // Abre el diálogo de exportación
    };

    const closeExportDialog = () => {
        setOpenExportDialog(false);
        setExportedMap(null);
    };

    const handleEdit = (mapId) => {
        // Redirige al componente de edición con navigate
        navigate(`/editMap/${mapId}`); // Cambio aquí para usar navigate
    };

    return (
        <div className="container">
            <header className="header">
                <h1>Mapas</h1>
            </header>
            <input
                type="text"
                placeholder="Buscador..."
                value={searchTerm}
                onChange={handleSearch}
                className="search-input"
            />
            <table className="maps-table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Nombre del Mapa</th>
                        <th>URL</th>
                        <th>Borrar Mapa</th>
                        <th>Exportar Mapa</th>
                        <th>Editar Mapa</th> {/* Nueva columna de edición */}
                    </tr>
                </thead>
                <tbody>
                    {filteredMaps.map(map => (
                        <tr key={map.id}>
                            <td>{map.id}</td>
                            <td>{map.map_name}</td>
                            <td>
                                <a href={`https://mapane3.tipicaweb.com/myMaps/${map.id}`} target="_blank" rel="noopener noreferrer">
                                    Ver Mapa
                                </a>
                            </td>
                            <td>
                                <button onClick={() => openDeleteConfirmation(map.id)} className="icon-button">
                                    <DeleteIcon style={{ color: 'black' }} />
                                </button>
                            </td>
                            <td>
                                <button onClick={() => handleExport(map)} className="icon-button">
                                    <GetAppIcon style={{ color: 'green' }} />
                                </button>
                            </td>
                            <td>
                                {/* Botón de editar con ícono de lápiz */}
                                <button onClick={() => handleEdit(map.id)} className="icon-button">
                                    <EditIcon style={{ color: 'blue' }} />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Diálogo de confirmación para eliminar */}
            <Dialog open={openDeleteDialog} onClose={closeDeleteDialog}>
                <DialogTitle>Confirmar Eliminación</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        ¿Estás seguro de que deseas eliminar este mapa? Esta acción no se puede deshacer.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteDialog} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleDelete} color="secondary">
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Diálogo de confirmación para exportación */}
            <Dialog open={openExportDialog} onClose={closeExportDialog}>
                <DialogTitle>Exportación Exitosa</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Mapa "{exportedMap?.map_name}" exportado como Excel exitosamente.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeExportDialog} color="primary">
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default SearchMyMapas;
