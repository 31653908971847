import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './shared/header/Header';
import Sidebar from './shared/Sidebar';
import RecorridoBuses from './components/buses/RecorridoBuses';
import MarcadoresInteres from './components/puntosInteres/MarcadoresInteres';
import MarcaPoligonos from './components/poligonos/MarcaPoligonos';
import Mymaps from './components/mymaps/Mymaps';
import SearchMyMapas from './components/consultaMaps/searchMyMapas';
import EditMapPage from './components/consultaMaps/EditMapPage';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import './App.css';
import axios from 'axios';
import ConsultaMarcadores from './components/consultaMarcadores/consultaMarcadores';
import FullMapPage from './components/mapaPage/FullMapPage'; // Importación correcta del componente FullMapPage
import Login from './components/login/Login'; // Importación correcta del componente Login
import './components/login/Login.css'; // Importación del archivo CSS para los estilos del Login
import ProtectedRoute from './ProtectedRoute'; // Asegúrate de importar el componente ProtectedRoute
import MarcadoresMapa from './components/consultaMarcadores/marcadoresMapa/marcadoresMapa';
import EditarMarcadores from './components/consultaMarcadores/editarMarcadores/EditarMarcadores';
import ConsultaPoligono from './components/consultaPoligono/ConsultaPoligono';
import EditarPoligono from './components/consultaPoligono/editarPoligono/EditarPoligono';

function App() {
    const [data, setData] = useState([]);
    const [linea, setLinea] = useState('');
    const [loading, setLoading] = useState(false);
    const [fileLoaded, setFileLoaded] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const token = sessionStorage.getItem('token');  // Cambiar a sessionStorage
        setIsAuthenticated(!!token);  // Si hay un token, el usuario está autenticado
    }, []);
    const handleFileUpload = (uploadedData) => {
        setData(uploadedData);
        setFileLoaded(true);
    };

    const handleSave = () => {
        if (data.length > 0 && linea) {
            const dataWithLine = data.map(item => ({
                ...item,
                linea: linea,
            }));

            setLoading(true);
            axios.post('https://mapane3.tipicaweb.com/api/api/saveBusData', { data: dataWithLine })
                .then((response) => {
                    setSnackbarSeverity('success');
                    setSnackbarMessage('Archivo guardado exitosamente');
                    setSnackbarOpen(true);
                })
                .catch((error) => {
                    setSnackbarSeverity('error');
                    setSnackbarMessage('Error al guardar archivo');
                    setSnackbarOpen(true);
                })
                .finally(() => {
                    setLoading(false);
                    setData([]);
                    setFileLoaded(false);
                    setLinea('');
                });
        } else {
            setSnackbarSeverity('error');
            setSnackbarMessage('No hay datos para guardar o no se ingresó la línea');
            setSnackbarOpen(true);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
                <Route path="/myMaps/:id" element={<FullMapPage />} /> {/* Nueva ruta para la página del mapa */}
                
                <Route 
                    path="*" 
                    element={
                        <ProtectedRoute>
                            <MainContent
                                data={data}
                                handleFileUpload={handleFileUpload}
                                handleSave={handleSave}
                                fileLoaded={fileLoaded}
                                linea={linea}
                                setLinea={setLinea}
                                loading={loading}
                            />
                        </ProtectedRoute>
                    }
                />
            </Routes>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Router>
    );
}

function MainContent({ data, handleFileUpload, handleSave, fileLoaded, linea, setLinea, loading }) {
    return (
        <div className="App">
            <Header />
            <Sidebar />
            <div className="content">
                <Routes>
                    <Route path="/" element={<SearchMyMapas />} /> 
                    <Route path="/recorrido" element={<RecorridoBuses />} />
                    <Route path="/marcadores-interes" element={<MarcadoresInteres />} />
                    <Route path="/consulta-marcadores" element={<ConsultaMarcadores />} />
                    <Route path="/marca-poligonos" element={<MarcaPoligonos />} />
                    <Route path="/consulta-poligonos" element={<ConsultaPoligono />} />
                    <Route path="/my-maps" element={<Mymaps />} />
                    <Route path="/editar-poligono/:nombre_zona" element={<EditarPoligono />} />

                    <Route path="/my-maps-search" element={<SearchMyMapas />} />
                    <Route path="/editMap/:id" element={<EditMapPage />} /> {/* Ruta para editar el mapa */}
                <Route path="/editar/:nombre" element={<EditarMarcadores />} />
                <Route path="/mapa/:nombre" element={<MarcadoresMapa />} /> {/* Ruta para el mapa de marcadores */}
                </Routes>
                {loading && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <CircularProgress />
                    </Box>
                )}
            </div>
        </div>
    );
}

export default App;
