import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import './IconSelector.css';

// Función para importar todas las imágenes de una carpeta de forma dinámica
const importAll = (r) => {
    let images = {};
    r.keys().forEach((item) => { 
        images[item.replace('./', '')] = r(item); 
    });
    return images;
};

// Importa todas las imágenes de la carpeta `assets/icons`
const localIcons = importAll(require.context('../../assets/icons', false, /\.(png|jpe?g|svg)$/));

// Importa todas las imágenes de la carpeta `assets/icons2`
const localIcons2 = importAll(require.context('../../assets/icons2', false, /\.(png|jpe?g|svg)$/));

// Íconos organizados por categoría, incluyendo URLs y rutas locales
const iconCategories = {
    "Transportes": [
        'https://maps.google.com/mapfiles/kml/pal2/icon21.png',
        'https://maps.google.com/mapfiles/kml/pal2/icon7.png',
        'https://maps.google.com/mapfiles/kml/pal2/icon48.png',
        'https://maps.google.com/mapfiles/kml/pal2/icon39.png',
    ],
    "Lugares de Interés": [
        'https://maps.google.com/mapfiles/kml/pal2/icon11.png',
        'https://maps.google.com/mapfiles/kml/pal2/icon10.png',
        'https://maps.google.com/mapfiles/kml/pal2/icon0.png',
        'https://maps.google.com/mapfiles/kml/pal2/icon19.png',
        'https://maps.google.com/mapfiles/kml/pal2/icon32.png',
        'https://maps.google.com/mapfiles/kml/pal2/icon49.png',
        'https://maps.google.com/mapfiles/kml/pal2/icon52.png',
        'https://maps.google.com/mapfiles/kml/pal2/icon4.png',
        'https://maps.google.com/mapfiles/kml/pal4/icon55.png',
        'https://maps.google.com/mapfiles/kml/pal2/icon54.png',
        'https://maps.google.com/mapfiles/kml/pal3/icon18.png',
    ],
    "marcadores": [
        ...Object.values(localIcons) // Agrega los íconos de `assets/icons`
    ],
    "marcadores 2": [
        ...Object.values(localIcons2) // Agrega los íconos de `assets/icons2`
    ]
};

const IconSelector = ({ onSelectIcon }) => {
  return (
    <Box display="flex" flexDirection="column" gap={3}>
      {Object.entries(iconCategories).map(([category, icons]) => (
        <Box key={category}>
          <Typography variant="h6">{category}</Typography>
          <Box display="flex" flexWrap="wrap" gap={2}>
            {icons.map((icon, index) => (
              <Button key={index} onClick={() => onSelectIcon(icon)}>
                <img 
                  src={icon} 
                  alt={`Icon ${index}`} 
                  style={{ width: 32, height: 32 }} 
                  onError={(e) => e.target.style.display = 'none'} // Oculta ícono si no carga
                />
              </Button>
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default IconSelector;
